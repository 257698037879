import React from 'react';
import styled from 'styled-components';
import axios from 'axios';
import moment from 'moment';
import {Link} from 'react-router-dom';
import {FieldSet, FieldTitle, FieldSub, FieldBody, FieldLabel, FieldDesc} from '../ui/field';
import Zip2addr from '../zip2addr';
import FormPartsStage from '../ui/form_parts_stage';

class QuestionViewer extends React.Component {
  constructor(props) {
    super();
    const {question, answers, active} = props;
    const prefix = 'q_' + question.id;
    this.state = {
      question: question,
      my_answer: {
        [prefix]: answers[prefix] || {}
      },
      expanded: []
    };

    this.updateState = props.updateState;
  }
  changeActive(ev, label) {
    const {expanded} = this.state;
    let nextExpanded = expanded;
    if ( expanded.includes(label) ) {
      // 抜く
      nextExpanded = nextExpanded.filter((e) => {
        return e !== label;
      });
      console.log(nextExpanded);
    } else {
      // 追加
      nextExpanded = nextExpanded.concat(label);
    }

    this.setState(state => {
      const nextState = {
        ...state,
        expanded: nextExpanded
      };
      return nextState;
    });
  }
  changeValue(ev, prefix, label) {
    const {name, value} = ev.target;
    const my_answer = {
      ...this.state.my_answer, 
      [prefix]: {
        ...this.state.my_answer[prefix],
        [label]: value
      }
    };
    this.setState(state => {
      const nextState = {
        ...state,
        my_answer
      };
      return nextState;
    });
    this.updateState(my_answer);
  }
  changeCheck(ev, prefix, label) {
    const {my_answer} = this.state;
    const {checked, value} = ev.target;
    let list = my_answer[prefix][label] || [];
    list = checked ? list.concat(value) : list.filter((l) => {
      return l !== value;
    });
    const next_answer = {
      ...this.state.my_answer,
      [prefix]: {
        ...this.state.my_answer[prefix],
        [label]: list
      }
    };
    this.setState(state => {
      const nextState = {
        ...state,
        my_answer: next_answer
      };
      return nextState;
    });
    this.updateState(next_answer);
  }

  render() {
    const { question, my_answer, expanded } = this.state;
    let items = question.items_json && JSON.parse(question.items_json);
    const prefix = 'q_' + question.id;
    const answer = my_answer[prefix];

    if ( question.display_type == 1 || question.display_type == 2 ) {
      for( let data in items.values ){
        let match = items.values[data].text.match(/\[(.*)\](.*)/);
        if ( match ) {
          if ( match[1] && match[2] ) {
            items.values[data].parent = match[1];
            items.values[data].text   = match[2];
          }
        } else {
          items.values[data].parent = items.values[data].text;
          items.values[data].text = items.values[data].text;
        }
      }
    }
    let groups = [];
    for( let data in items.values ){
      const parent = items.values[data].parent ? items.values[data].parent : "normal";
      let index = groups.findIndex((g) => {
          return (g.name === parent);
      });
      if ( index == -1 ) {
        index = groups.push({
          name: parent,
          values: []
        });
        index = index - 1;
      }
      groups[index].values.push(items.values[data]);
    }
    items.groups = groups;

    if ( question.layout_type == 0 ) {
      return (<FormPartsTitle data={question}/>);
    } else if ( question.layout_type == 1 ) {
      return (<FormPartsCheck prefix={prefix} data={question} items={items} answer={answer} changeCheck={this.changeCheck.bind(this)} changeValue={this.changeValue.bind(this)} changeActive={this.changeActive.bind(this)} expanded={expanded}/>);
    } else if ( question.layout_type == 2 ) {
      return (<FormPartsRadio prefix={prefix} data={question} items={items} answer={answer} changeCheck={this.changeCheck.bind(this)} changeValue={this.changeValue.bind(this)} changeActive={this.changeActive.bind(this)} expanded={expanded}/>);
    } else if ( question.layout_type == 3 ) {
      return (<FormPartsRadioSep prefix={prefix} data={question} items={items} answer={answer} changeCheck={this.changeCheck.bind(this)} changeValue={this.changeValue.bind(this)} changeActive={this.changeActive.bind(this)} expanded={expanded}/>);
    } else if ( question.layout_type == 4 ) {
      return (<FormPartsStage prefix={prefix} data={question} items={items} answer={answer} changeCheck={this.changeCheck.bind(this)} changeValue={this.changeValue.bind(this)} changeActive={this.changeActive.bind(this)} expanded={expanded} print={false}/>);
    }
  }
}
const FormPartsTitle = ({data}) => (
  <PartsWrap>
    <PartsTitle>{data.text}</PartsTitle>
  </PartsWrap>
);
const PartsGroup = styled.div`
  margin: 0 0 10px;

  ${props => {
    switch(props.type) {
        case 'with-image':
        return `
          display: flex;
          justify-content: flex-start;
          flex-wrap: wrap;

          label {
            width: 18%;
            margin: 0 20px 20px 0;
            position: relative;

            input {
              position: absolute;
              top: 5px;
              left: 5px;
            }

            &:nth-child(5n) {
              margin-right: 0;
            }

            img {
              width: 100%;
            }
          }

          @media (max-width:900px) {
            justify-content: space-between;
            label {
              width: 30%;
              margin-right: 0;
            }
            &:after{
              content:"";
              display: block;
              width:30%;
            }
          }

          @media (max-width:500px) {
            label {
              width: 47%;
              margin-right: 0;
            }
            &:after {
              display: none;
            }
          }
        `;
        default:
        return ``;
    }
  }}
`;

const ParentTitle = styled.div`
  font-size: 1.2em;
  margin: 10px 0 10px;

  @media (max-width:900px) {
    font-size: 1.4em;
  }

  ${props => {
    switch(props.type) {
        case 'accordion':
        return `
          cursor: pointer;
          position: relative;
          text-indent: 1em;
          vertical-align: middle;

          &:before {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            margin: auto;
            content: "";
            vertical-align: middle;
            left: 4px;
            box-sizing: border-box;
            width: 4px;
            height: 4px;
            border: 4px solid transparent;
            border-left: 4px solid #6E574D;
            margin-right: 5px;
            transform: ${props.active ? `rotate(90deg)` : `rotate(0)`};
            transform-origin: 30% 50%;
          }
        `;
        default:
        return `
            display: block;
        `;
    }
  }}
`;
const FormPartsCheck = ({prefix, data, items, answer, changeCheck, changeValue, changeActive, expanded}) => (
  <PartsWrap>
    <FieldSet>
      <FieldTitle type="large">
        <span>
          {data.text}（複数選択可）
          {data.display_type == 1 && <><br/><FieldSub marker={data.number == 24}>▶をクリックすると詳細な項目が表示されます</FieldSub></>}
        </span>
      </FieldTitle>
      <FieldBody type={items.type}>
        {items.groups.map((group) => (
          <div key={group.name}>
            {( group.values.length > 1 && group.name !== 'normal' ) && <ParentTitle active={expanded.includes(prefix + group.name)} type={data.display_type == 1 ? "accordion" : "normal"} onClick={(ev) => changeActive(ev, prefix + group.name)}>{group.name}</ParentTitle>}
            <PartsGroup type={items.type}>
              {group.values.map((v) => (
                <FieldLabel key={v.id} active={expanded.includes(prefix + group.name)} type={(data.display_type == 1 && group.values.length != 1 ) ? "accordion" : "normal"}>
                {v.image
                  ? <div><input type="checkbox" name={`${prefix}_item`} value={v.id} onChange={(ev) => changeCheck(ev, prefix, 'item')} checked={answer.item&&answer.item.includes(String(v.id))}/><img src={v.image + `?v3`}/><span>{v.text}</span></div>
                  : <div><input type="checkbox" name={`${prefix}_item`} value={v.id} onChange={(ev) => changeCheck(ev, prefix, 'item')} checked={answer.item&&answer.item.includes(String(v.id))}/>{v.text}</div>
                }
                </FieldLabel>
              ))}
            </PartsGroup>
          </div>
        ))}
        {data.flag_other == 1 &&
          <FieldLabel><input type="checkbox" name={`${prefix}_item`} value="99" onChange={(ev) => changeCheck(ev, prefix, 'item')} checked={answer.item&&answer.item.includes('99')}/>その他</FieldLabel>
        }
        {data.flag_memo == 1 &&
          <div>
            <FieldLabel>
              <FieldDesc>{data.memo_text}</FieldDesc>
              <textarea name={`${prefix}_other`} value={answer ? answer.other : ''} placeholder={data.memo_placeholder} onChange={(ev) => changeValue(ev, prefix, 'other')}/>
            </FieldLabel>
          </div>
        }
      </FieldBody>
    </FieldSet>
  </PartsWrap>
);
const FormPartsRadio = ({prefix, data, items, answer, changeCheck, changeValue, changeActive, expanded}) => (
  <PartsWrap>
    <FieldSet>
      <FieldTitle type="large">{data.text}</FieldTitle>
      <FieldBody type={items.type}>
        {items.groups.map((group) => (
          <PartsGroup key={group.name}>
            {group.name !== 'normal' && <ParentTitle active={expanded.includes(prefix + group.name)} type={data.display_type == 1 ? "accordion" : "normal"} onClick={(ev) => changeActive(ev, prefix + group.name)}>{group.name}</ParentTitle>}
            {group.values.map((v) => (
              <React.Fragment>
                <FieldLabel key={v.id} active={expanded.includes(prefix + group.name)} type={data.display_type == 1 ? "accordion" : "normal"}>
                {v.image
                  ? <div><input type="radio" name={`${prefix}_item`} value={v.id} onChange={(ev) => changeValue(ev, prefix, 'item')} checked={answer.item && answer.item == String(v.id)}/><img src={v.image+ `?v3`}/><span>{v.text}</span></div>
                  : <div><input type="radio" name={`${prefix}_item`} value={v.id} onChange={(ev) => changeValue(ev, prefix, 'item')} checked={answer.item && answer.item == String(v.id)}/>{v.text}</div>
                }
                </FieldLabel>
                {(data.number == 14 && v.id == 1) &&
                  <YmPicker prefix={prefix} year={answer.year} month={answer.month} changeValue={changeValue.bind(this)}/>
                }
                {(data.number == 14 && v.id == 2) &&
                  <YmRangePicker prefix={prefix} year_range={answer.year_range} month_range={answer.month_range} changeValue={changeValue.bind(this)}/>
                }
              </React.Fragment>
            ))}
          </PartsGroup>
        ))}

        {data.flag_other == 1 && 
          <FieldLabel><input type="radio" name={`${prefix}_item`} value="99" onChange={(ev) => changeCheck(ev, prefix, 'item')} checked={answer && answer.item && answer.item.includes('99')}/>その他</FieldLabel>
        }
        {data.flag_memo == 1 &&
          <div>
            <FieldLabel>
              <FieldDesc>{data.memo_text}</FieldDesc>
              <textarea name={`${prefix}_other`} value={answer ? answer.other : ''} placeholder={data.memo_placeholder} onChange={(ev) => changeValue(ev, prefix, 'other')}/>
            </FieldLabel>
          </div>
        }
      </FieldBody>
    </FieldSet>
  </PartsWrap>
);
const FormPartsRadioSep = ({prefix, data, items, answer, changeCheck, changeValue, changeActive, expanded}) => (
  <PartsWrap>
    <FieldSet>
      <FieldTitle type="large">{data.text}</FieldTitle>
      <FieldBody type={items.type}>
        {items.groups.map((group) => (
          <PartsGroup key={group.name}>
            {group.name !== 'normal' && <ParentTitle active={expanded.includes(prefix + group.name)} type={data.display_type == 1 ? "accordion" : "normal"} onClick={(ev) => changeActive(ev, prefix + group.name)}>{group.name}</ParentTitle>}
            {group.values.map((v) => (
              <FieldLabel key={v.id} active={expanded.includes(prefix + group.name)} type={data.display_type == 1 ? "accordion" : "normal"}>
              {v.image
                ? <div><input type="radio" name={`${prefix}_${group.name}_item`} value={v.id} onChange={(ev) => changeValue(ev, prefix, `${group.name}_item`)} checked={answer[`${group.name}_item`] && answer[`${group.name}_item`] == String(v.id)}/><img src={v.image+ `?v3`}/><span>{v.text}</span></div>
                : <div><input type="radio" name={`${prefix}_${group.name}_item`} value={v.id} onChange={(ev) => changeValue(ev, prefix, `${group.name}_item`)} checked={answer[`${group.name}_item`] && answer[`${group.name}_item`] == String(v.id)}/>{v.text}</div>
              }
              </FieldLabel>
            ))}
          </PartsGroup>
        ))}
      </FieldBody>
    </FieldSet>
  </PartsWrap>
);

class YmPicker extends React.Component {
  constructor(props) {
    super();
    const {prefix, year, month} = props;

    const today = new Date();

    this.state = {
      prefix,
      def_y: today.getFullYear(),
      year: parseInt(year),
      month: parseInt(month)
    };
    console.log(this.state);

    this.changeValue = props.changeValue;
  }

  render() {
    const {prefix, year, month, def_y} = this.state;

    return (
      <YmWrap>
        <select name="year" onChange={(ev) => this.changeValue(ev, prefix, 'year')}>
          <option value=""></option>
          {
            Array.from(new Array(5)).map((_,i)=> i + def_y).map((y, i) => {
              return (<option key={i} value={y} selected={y == year}>{y}</option>);
            })
          }
        </select>
        &nbsp;年&nbsp;&nbsp;
        <select name="month" onChange={(ev) => this.changeValue(ev, prefix, 'month')}>
          <option value=""></option>
          {
            Array.from({length: 12}).map((_, i) => (
              <option key={i} value={i + 1} selected={i+1 == month}>{i + 1}</option>
            ))
          }
        </select>
        &nbsp;月
      </YmWrap>
    );
  }
}

class YmRangePicker extends React.Component {
  constructor(props) {
    super();
    const {prefix, year_range, month_range} = props;

    const today = new Date();

    this.state = {
      prefix,
      def_y: today.getFullYear(),
      year_range: parseInt(year_range),
      month_range: month_range,
    };
    console.log(this.state);

    this.changeValue = props.changeValue;
  }

  render() {
    const {prefix, year_range, month_range, def_y} = this.state;

    return (
      <YmWrap>
        <select name="year_range" onChange={(ev) => this.changeValue(ev, prefix, 'year_range')}>
          <option value=""></option>
          {
            Array.from(new Array(5)).map((_,i)=> i + def_y).map((y, i) => {
              return (<option key={i} value={y} selected={y == year_range}>{y}</option>);
            })
          }
        </select>
        &nbsp;年&nbsp;&nbsp;
        <select name="month_range" onChange={(ev) => this.changeValue(ev, prefix, 'month_range')}>
          <option value=""></option>
          <option value="1月~3月" selected={month_range == "1月~3月"}>1月~3月</option>
          <option value="4月~6月" selected={month_range == "4月~6月"}>4月~6月</option>
          <option value="7月~9月" selected={month_range == "7月~9月"}>7月~9月</option>
          <option value="10月~12月" selected={month_range == "10月~12月"}>10月~12月</option>
        </select>
      </YmWrap>
    );
  }
}


const YmWrap = styled.div`
  margin: 0 0 10px;
`;

class FormDetail extends React.Component {
  constructor(props) {
    super();
    const {form, sheet, questions, token} = props;
    const page_res = this.createPagenation(questions);

    this.state = {
      form: {
        id: form.id,
        charger: form.charger || '',
        groom_name: form.groom_name || '',
        groom_ruby: form.groom_ruby || '',
        groom_birthday: form.groom_birthday || '',
        groom_zip: form.groom_zip || '',
        groom_address: form.groom_address || '',
        groom_tel: form.groom_tel || '',
        groom_email: form.groom_email || '',
        groom_job: form.groom_job || '',
        groom_company: form.groom_company || '',
        bride_name: form.bride_name || '',
        bride_ruby: form.bride_ruby || '',
        bride_birthday: form.bride_birthday || '',
        bride_zip: form.bride_zip || '',
        bride_address: form.bride_address || '',
        bride_email: form.bride_email || '',
        bride_tel: form.bride_tel || '',
        bride_job: form.bride_job || '',
        bride_company: form.bride_company || '',
        limit_date: form.limit_date || '',
        flag_disabled: form.flag_disabled || '',
        answer_json: form.answer_json || '',
        passcode: form.passcode
      },
      sheet,
      questions,
      answers: form.answer_json ? JSON.parse(form.answer_json) : {},
      updated: false,
      tmp_updated: false,
      token: token || '',
      pages: page_res.pages,
      page: {
        page_total: page_res.pages.length,
        current: 1,
        list: page_res.list
      }
    };
  }

  createPagenation(questions) {
    let pages = [];
    let go_next = 0;
    let pages_part = [];
    let total = 0;
    let list = [];
    let page_total = 0;
    for ( let q of questions ) {
      if ( total > 1 && q.layout_type == 0 ) {
        go_next = true;
      } else {
        go_next = false;
      }

      if ( go_next ) {
        pages.push(pages_part);
        pages_part = [];

        page_total++;
        list.push(( "00" + page_total ).substr(-2));
      }
      pages_part.push(q);
      total++;
    }
    pages.push(pages_part);
    page_total++;
    list.push(( "00" + page_total ).substr(-2));
    const res = {
      pages,
      list
    }
    return res;
  }

  changeValue(ev, overide) {
    const {name, value} = ev.target;
    this.setState(state => {
      const nextState = {
        ...state,
        form: {
          ...state.form, 
          [overide||name]: value
        }
      };
      return nextState;
    });
  }

  doFormUpdate(ev) {
    const {form, answers} = this.state;

    axios.post('/api/forms/update', {
      form,
      answers
    })
      .catch(_ => alert('エラー'))
      .then(res => res.data.sheet)
      .then(sheet => {
        this.setState({updated: true});
      });
  }
  doFormTmpUpdate(ev) {
    const {form, answers} = this.state;

    axios.post('/api/forms/update', {
      form,
      answers
    })
      .catch(_ => alert('エラー'))
      .then(res => res.data.sheet)
      .then(sheet => {
        this.setState({tmp_updated: true});
        alert("入力内容を保存しました");
      });
  }
  updateState(answer){
    const {answers} = this.state;
    this.setState(state => {
      const nextAnswers = {
        ...answers,
        ...answer
      };
      return {answers: nextAnswers};
    });
  }

  goNext(ev) {
    window.scrollTo(0, 0);
    const {page} = this.state;
    const next_page = {
      ...page,
      current: page.current + 1
    };
    this.setState({page: next_page});
  }
  goPrev(ev) {
    window.scrollTo(0, 0);
    const {page} = this.state;
    const next_page = {
      ...page,
      current: page.current - 1
    };
    this.setState({page: next_page}); 
  }
  goPage(ev, p) {
    window.scrollTo(0, 0);
    const {page} = this.state;
    const next_page = {
      ...page,
      current: p || 1
    };
    this.setState({page: next_page}); 
  }

  searchAddr(origin, target) {
    const {form} = this.state;
    const zip = form[origin];
    if ( zip && ( zip.match(/^[0-9]{7}$/) || zip.match(/^[0-9]{3}\-[0-9]{4}$/) ) ) {
      Zip2addr(zip)
        .then(res => {
          if ( res.results ) {
            const addr = res.results[0]
            const addr_string = addr.address1 + addr.address2 + addr.address3;
            const next_form = {
              ...form,
              [target]: addr_string
            };
            this.setState({form: next_form});
            console.log(addr_string);
          }
        });
    }
  }

  render() {
    const {form, sheet, questions, answers, updated, token, page, pages} = this.state;

    if ( updated ) {
      return (
        <Wrapper>
          <Message>
          ご協力いただきありがとうございます<br/>アンケート内容を保存しました
          </Message>
          <Exit><a href="https://www.arluis.com/">公式サイトへ</a></Exit>
        </Wrapper>
      );
    }

    return (
      <Wrapper>
        {page.current == 1 && <FormBody token={token} form={form} changeValue={this.changeValue.bind(this)} searchAddr={this.searchAddr.bind(this)}/>}
        {pages[page.current - 1].map((q) => <QuestionViewer key={q.id} question={q} answers={answers} updateState={this.updateState.bind(this)}/>)}
      
        {page.current == page.end && <FormFooter>
          <FooterText>ご記入頂いた個人情報は弊社担当者からご連絡差し上げるため、また挙式に関するご提案をスムーズにさせていただくために利用いたします。弊社でご成約頂けなかった場合は、本シートはご記入から6ヶ月後に破棄し、それ以降個人情報を弊社が利用することはありません。</FooterText>
        </FormFooter>}

        <FormPagenationPc>
          {page.current !== page.page_total ? <TmpSend onClick={ev => this.doFormTmpUpdate(ev)} active={true}>一時保存</TmpSend> : <div/>}
          <PageLength>
            {page.current} / {page.page_total}
          </PageLength>
          {page.current > 1 ? <Prev onClick={ev => this.goPrev(ev)}>戻る</Prev> : <div/>}
          {page.current < page.page_total ? <Next onClick={ev => this.goNext(ev)} active={true}>次へ</Next> : <div/>}
          {page.current == page.page_total ? <Send onClick={ev => this.doFormUpdate(ev)} active={true}>保存する</Send> : <div/>}
        </FormPagenationPc>
        <FormPagenationSp>
          <SpBtns>
            {page.current > 1 ? <Prev onClick={ev => this.goPrev(ev)}>戻る</Prev> : <div/>}
            <PageLength>
              {page.current} / {page.page_total}
            </PageLength>
            {page.current < page.page_total ? <Next onClick={ev => this.goNext(ev)} active={true}>次へ</Next> : <div/>}
          </SpBtns>

          <SpSends>
            {page.current == page.page_total ? <Send onClick={ev => this.doFormUpdate(ev)} active={true}>保存する</Send> : <div/>}
            {page.current !== page.page_total ? <TmpSend onClick={ev => this.doFormTmpUpdate(ev)} active={true}>一時保存</TmpSend> : <div/>}
          </SpSends>
        </FormPagenationSp>

        <PageIndex page={page} goPage={this.goPage.bind(this)}/>
      </Wrapper>
    );
  }
}
const PageIndex = ({page, goPage}) => (
  <PageIndexWrapper>
    <PageList>
      {page.list.map((p) => (
        <PageItem key={p} current={page.current==parseInt(p)} onClick={ev => goPage(ev, parseInt(p))}><span>{p}</span></PageItem>
      ))}
    </PageList>
  </PageIndexWrapper>
);
const PageIndexWrapper = styled.div`
  position: fixed;
  top: 30px;
  right: 30px;
`;
const PageList = styled.ul``;
const PageItem = styled.li`
  display: flex;
  align-items: center;
  cursor: pointer;

  span {
    display: inline-block;
    font-family: serif;
    font-size: 10px;
    margin: 0 5px 0 0;
    color: ${props => props.current ? `#6E574D` : `#D1C7C2`};
  }

  &:after {
    content: "";
    display: block
    width: 5px;
    height: 30px;
    background-color: ${props => props.current ? `#6E574D` : `#D1C7C2`};
  }

  @media (max-width:320px) {
    display: none;
  }
`;


const FormBody = ({token, form, changeValue, searchAddr}) => (
  <FormBodyWrapper>
    {token &&
      <ToPrint>
        <a href={`/form/${form.passcode}/print`} target="_blank">印刷ページを表示</a>
      </ToPrint>
    }
    <FormIntro>
      <Description>
        全てのページで入力が完了しましたら「保存」ボタンを押して入力内容を保存してください。<br/>
        ページ下部の「一時保存」を押すと、入力途中の内容を保存することができます。
      </Description>
    </FormIntro>
    {token &&
      <FormUserdata>
        <User>
          <UserTitle>
            新郎様/Groom
          </UserTitle>
          <UserBody>
            <FieldSet>
              <FieldTitle>お名前</FieldTitle>
              <FieldBody><input type="text" name="groom_name" value={form.groom_name} autoComplete="off" onChange={(ev) => changeValue(ev)}/></FieldBody>
            </FieldSet>
            <FieldSet>
              <FieldTitle>フリガナ</FieldTitle>
              <FieldBody><input type="text" name="groom_ruby" value={form.groom_ruby} autoComplete="off" onChange={(ev) => changeValue(ev)}/></FieldBody>
            </FieldSet>
            <FieldSet>
              <FieldTitle>お誕生日</FieldTitle>
              <FieldBody><input type="text" name="groom_birthday" value={form.groom_birthday} autoComplete="off" onChange={(ev) => changeValue(ev)}/></FieldBody>
            </FieldSet>
            <FieldSet>
              <FieldTitle>郵便番号</FieldTitle>
              <FieldBody type="zip">
                <input type="text" name="groom_zip" value={form.groom_zip} autoComplete="off" onChange={(ev) => changeValue(ev)}/>
                <BtnZip onClick={((ev) => searchAddr('groom_zip', 'groom_address'))}>住所を検索</BtnZip>
              </FieldBody>
            </FieldSet>
            <FieldSet>
              <FieldTitle>ご住所</FieldTitle>
              <FieldBody><input type="text" name="groom_address" value={form.groom_address} autoComplete="off" onChange={(ev) => changeValue(ev)}/></FieldBody>
            </FieldSet>
            <FieldSet>
              <FieldTitle>メールアドレス</FieldTitle>
              <FieldBody><input type="email" name="groom_email" value={form.groom_email} autoComplete="off" onChange={(ev) => changeValue(ev)}/></FieldBody>
            </FieldSet>
            <FieldSet>
              <FieldTitle>電話番号</FieldTitle>
              <FieldBody><input type="text" name="groom_tel" value={form.groom_tel} autoComplete="off" onChange={(ev) => changeValue(ev)}/></FieldBody>
            </FieldSet>
            <FieldSet>
              <FieldTitle>ご職業</FieldTitle>
              <FieldBody><input type="text" name="groom_job" value={form.groom_job} autoComplete="off" onChange={(ev) => changeValue(ev)}/></FieldBody>
            </FieldSet>
            <FieldSet>
              <FieldTitle>会社名</FieldTitle>
              <FieldBody><input type="text" name="groom_company" value={form.groom_company} autoComplete="off" onChange={(ev) => changeValue(ev)}/></FieldBody>
            </FieldSet>
          </UserBody>
        </User>
        <User>
          <UserTitle>
            新婦様/Bride
          </UserTitle>
          <UserBody>
            <FieldSet>
              <FieldTitle>お名前</FieldTitle>
              <FieldBody><input type="text" name="bride_name" value={form.bride_name} autoComplete="off" onChange={(ev) => changeValue(ev)}/></FieldBody>
            </FieldSet>
            <FieldSet>
              <FieldTitle>フリガナ</FieldTitle>
              <FieldBody><input type="text" name="bride_ruby" value={form.bride_ruby} autoComplete="off" onChange={(ev) => changeValue(ev)}/></FieldBody>
            </FieldSet>
            <FieldSet>
              <FieldTitle>お誕生日</FieldTitle>
              <FieldBody><input type="text" name="bride_birthday" value={form.bride_birthday} autoComplete="off" onChange={(ev) => changeValue(ev)}/></FieldBody>
            </FieldSet>
            <FieldSet>
              <FieldTitle>郵便番号</FieldTitle>
              <FieldBody type="zip">
                <input type="text" name="bride_zip" value={form.bride_zip} autoComplete="off" onChange={(ev) => changeValue(ev)}/>
                <BtnZip onClick={((ev) => searchAddr('bride_zip', 'bride_address'))}>住所を検索</BtnZip>
              </FieldBody>
            </FieldSet>
            <FieldSet>
              <FieldTitle>ご住所</FieldTitle>
              <FieldBody><input type="text" name="bride_address" value={form.bride_address} autoComplete="off" onChange={(ev) => changeValue(ev)}/></FieldBody>
            </FieldSet>
            <FieldSet>
              <FieldTitle>メールアドレス</FieldTitle>
              <FieldBody><input type="email" name="bride_email" value={form.bride_email} autoComplete="off" onChange={(ev) => changeValue(ev)}/></FieldBody>
            </FieldSet>
            <FieldSet>
              <FieldTitle>電話番号</FieldTitle>
              <FieldBody><input type="text" name="bride_tel" value={form.bride_tel} autoComplete="off" onChange={(ev) => changeValue(ev)}/></FieldBody>
            </FieldSet>
            <FieldSet>
              <FieldTitle>ご職業</FieldTitle>
              <FieldBody><input type="text" name="bride_job" value={form.bride_job} autoComplete="off" onChange={(ev) => changeValue(ev)}/></FieldBody>
            </FieldSet>
            <FieldSet>
              <FieldTitle>会社名</FieldTitle>
              <FieldBody><input type="text" name="bride_company" value={form.bride_company} autoComplete="off" onChange={(ev) => changeValue(ev)}/></FieldBody>
            </FieldSet>
          </UserBody>
        </User>
      </FormUserdata>
    }
  </FormBodyWrapper>
);

const Message = styled.div`
  text-align: center;
  font-size: 14px;
  color: #6E574D;
  margin: 80px 0 40px;
`;
const Exit = styled.div`
  text-align: center;
  a {
    display: inline-block;
    padding: 10px 40px;
    background-color: #6E574D;
    color: #fff;
    text-decoration: none;
    transition: all 0.5s;

    &:hover {
      opacity: 0.6;
      text-decoration: none;
    }
  }
`;

const Wrapper = styled.div``;
const FormIntro = styled.div`
  max-width: 320px;
  margin: 0 auto 60px;
`;
const Description = styled.div`
  text-align: justify;
  font-size: 12px;
  margin: 0 0 20px;
  line-height: 1.8em;
`;

const FormBodyWrapper = styled.div`
`;
const FormFooter = styled.div`
  border-top: 1px solid #6E574D;
  margin: 0 0 60px;
`;
const FooterText = styled.div`
  padding: 1em 0 0;
  font-size: 12px;
  text-align: justify;
`;
const FormUserdata = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 0 0 60px;
  @media (max-width:600px) {
    display: block;
  }
`;
const User = styled.div`
  width: 48%;
  @media (max-width:600px) {
    max-width: 320px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }
`;
const UserTitle = styled.div`
  color: #6E574D;
  font-size: 14px;
  margin: 0 0 20px;
`;
const UserBody = styled.div`
`;
const PartsWrap = styled.div`
  margin: 0 0 60px;
`;
const PartsTitle = styled.div`
  margin: 0 0 40px;
  border-bottom: 1px solid #6E574D;
  color: #6E574D;
  font-size: 14px;
`;
const FormPagenation = styled(PartsWrap)`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;
const FormPagenationPc = styled(FormPagenation)`
  display: flex;
  @media (max-width:500px) {
    display: none;
  }
`;
const FormPagenationSp = styled(FormPagenation)`
  display: none;
  @media (max-width:500px) {
    display: block;
  }
`;
const SpBtns = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 0 40px;

  div {
    width: 30%;
    margin: 0;
    text-align: center;
  }
`;
const SpSends = styled.div`
  div {
    width: 100%;
    box-sizing: border-box;
    margin: 0;
  }
`;
const PageLength = styled.div`
  margin: 0 0 0 20px;
  color: #6E574D;
`;
const BtnPager = styled.div`
  display: inline-block;  
  background: ${props => props.active ? `#6E574D` : `#D1C7C2`};
  color: ${props => props.active ? `#FFFFFF` : `#6E574D`};
  padding: 10px;
  width: 120px;
  text-align: center;
  margin-left: 20px;
  cursor: pointer;
  transition: 0.5s all;

  &:hover {
    opacity: 0.8;
  }
`;
const ToPrint = styled(BtnPager)`
  background: #6E574D;
  position: absolute;
  top: 20px;
  right: 0;

  a {
    text-decoration: none;
    color: #fff;
  }
`;
const Prev = styled(BtnPager)`
`;
const Next = styled(BtnPager)`
`;
const BtnZip = styled.button`
  border-radius: 5px;
  background-color: #ccc;
  box-shadow: none;
`;
const Send = styled(BtnPager)``;
const TmpSend = styled(BtnPager)`
  background: #D1C7C2;
  color: #6E574D;
  margin: 0 auto 0 0;
`;

class Loader extends React.Component {
  constructor(props) {
    super();

    const {passcode} = props.match.params;

    this.state = {
      passcode, 
      form: {},
      sheet: {},
      questions: {}
    };
  }

  componentWillMount() {
    const {passcode} = this.state;
    axios.get('/api/forms/find', {
      params: {
        passcode
      }
    })
      .catch(_ => console.error('Do not get form.'))
      .then(res => res.data.form)
      .then((form) => {
        this.setState(state => {
          return {form};
        });

        axios.get('/api/sheets/find', {
          params: {
            id: form.sheets_id
          }
        })
        .catch(_ => console.error('Do not get sheet.'))
        .then(res => res.data.sheet)
        .then((sheet) => {
          this.setState(state => {
            return {sheet};
          });

          axios.get('/api/questions/select', {
            params: {
              sheets_id: sheet.id
            }
          })
          .catch(_ => console.error('Do not get questions.'))
          .then(res => res.data.questions)
          .then((questions) => {
            this.setState(state => {
              return {questions};
            });
          });
        })
      });
  }

  render() {
    const {form, sheet, questions} = this.state;
    const token = localStorage.getItem('admin');
    if ( form.id && sheet.id && questions.length > 0 ) {
      return <FormDetail form={form} sheet={sheet} questions={questions} token={token}/>;
    } else {
      return <div/>;
    }
  }
}

export default Loader;