import React, {Component} from 'react';
import styled from 'styled-components';
import {FieldSet, FieldTitle, FieldSub, FieldBody, FieldLabel, FieldGroup, FieldWrap, FieldDesc} from '../ui/field';

export default ({prefix, data, items, answer, changeCheck, changeValue, changeActive, expanded, print}) => {
  return print ?
    (
    <Wrapper>
      <FieldSet>
        <FieldTitle type="large">{data.text}</FieldTitle>
        <FieldBody type={items.type}>
          {items.values.map((v) => (
            <FieldWrap key={v.id}>
              <Text>{v.text}</Text>
              <List>
                  {answer[v.id + '_item'] == 1 && <Label>１（全くあてはまらない）</Label>}
                  {answer[v.id + '_item'] == 2 && <Label>２（あてはまらない）</Label>}
                  {answer[v.id + '_item'] == 3 && <Label>３（どちらともいえない）</Label>}
                  {answer[v.id + '_item'] == 4 && <Label>４（あてはまる）</Label>}
                  {answer[v.id + '_item'] == 5 && <Label>５（とてもあてはまる）</Label>}
                </List>
            </FieldWrap>
          ))}
        </FieldBody>
      </FieldSet>
    </Wrapper>
    ) : (
    <Wrapper>
      <FieldSet>
        <FieldTitle type="large">{data.text}</FieldTitle>
        <FieldBody type={items.type}>
          {items.groups.map((group) => (
            <PartsGroup key={group.name}>
              {group.values.map((v) => (
                <FieldWrap key={v.id}>
                  <Text>{v.text}</Text>
                  <List>
                      <Label><input type="radio" name={`${prefix}_${v.id}_item`} value="1" onChange={(ev) => changeValue(ev, prefix, `${v.id}_item`)} checked={answer[`${v.id}_item`] && answer[`${v.id}_item`] == "1"}/>１（全くあてはまらない）</Label>
                      <Label><input type="radio" name={`${prefix}_${v.id}_item`} value="2" onChange={(ev) => changeValue(ev, prefix, `${v.id}_item`)} checked={answer[`${v.id}_item`] && answer[`${v.id}_item`] == "2"}/>２（あてはまらない）</Label>
                      <Label><input type="radio" name={`${prefix}_${v.id}_item`} value="3" onChange={(ev) => changeValue(ev, prefix, `${v.id}_item`)} checked={answer[`${v.id}_item`] && answer[`${v.id}_item`] == "3"}/>３（どちらともいえない）</Label>
                      <Label><input type="radio" name={`${prefix}_${v.id}_item`} value="4" onChange={(ev) => changeValue(ev, prefix, `${v.id}_item`)} checked={answer[`${v.id}_item`] && answer[`${v.id}_item`] == "4"}/>４（あてはまる）</Label>
                      <Label><input type="radio" name={`${prefix}_${v.id}_item`} value="5" onChange={(ev) => changeValue(ev, prefix, `${v.id}_item`)} checked={answer[`${v.id}_item`] && answer[`${v.id}_item`] == "5"}/>５（とてもあてはまる）</Label>
                    </List>
                </FieldWrap>
              ))}
            </PartsGroup>
          ))}
        </FieldBody>
      </FieldSet>
    </Wrapper>
  );
};
const List = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 0 0 10px;
  @media (max-width:600px) {
    display: block;
  }
`;
const Text = styled.div`
  font-size: 1.1em;
  font-weight: bold;
  margin: 0 0 10px;
`;
const Label = styled.label`
  display: flex;
  align-items: center;
  @media (max-width:600px) {
    margin-bottom: 10px;
  }
`;
const Wrapper = styled.div`
  margin: 0 0 60px;
`;
const ParentTitle = styled.div`
  font-size: 1.2em;
  margin: 10px 0 10px;

  @media (max-width:900px) {
    font-size: 1.4em;
  }

  ${props => {
    switch(props.type) {
        case 'accordion':
        return `
          cursor: pointer;
          position: relative;
          text-indent: 1em;
          vertical-align: middle;

          &:before {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            margin: auto;
            content: "";
            vertical-align: middle;
            left: 4px;
            box-sizing: border-box;
            width: 4px;
            height: 4px;
            border: 4px solid transparent;
            border-left: 4px solid #6E574D;
            margin-right: 5px;
            transform: ${props.active ? `rotate(90deg)` : `rotate(0)`};
            transform-origin: 30% 50%;
          }
        `;
        default:
        return `
            display: block;
        `;
    }
  }}
`;

const PartsGroup = styled.div`
  margin: 0 0 10px;

  ${props => {
    switch(props.type) {
        case 'with-image':
        return `
          display: flex;
          justify-content: flex-start;
          flex-wrap: wrap;

          label {
            width: 18%;
            margin: 0 20px 20px 0;
            position: relative;

            input {
              position: absolute;
              top: 5px;
              left: 5px;
            }

            &:nth-child(5n) {
              margin-right: 0;
            }

            img {
              width: 100%;
            }
          }

          @media (max-width:900px) {
            justify-content: space-between;
            label {
              width: 30%;
              margin-right: 0;
            }
            &:after{
              content:"";
              display: block;
              width:30%;
            }
          }

          @media (max-width:500px) {
            label {
              width: 47%;
              margin-right: 0;
            }
            &:after {
              display: none;
            }
          }
        `;
        default:
        return ``;
    }
  }}
`;

const PartsView = styled.div`
  display: inline-block;
  label {
    display: inline-block;

    &:after {
      content: "/";
      margin: 0 20px;
    }
  }
  &:last-child {
    label {
      &:after {
        display: none;
      }
    }
  }
`;
