import React from 'react';
import styled from 'styled-components';
import axios from 'axios';
import {Link} from 'react-router-dom';

const FormFinish = ({product, onOrder}) => (
  <Wrapper>
    
  </Wrapper>
);

const ProductInfo = styled.div`
  flex: auto;
`;

class Home extends React.Component {
  render() {
    const {form} = this.props;
    return (
      <div>
        {form.passcode}
      </div>
    );
  }
}

class Loader extends React.Component {
  constructor() {
    super();

    this.state = {
      form: []
    };
  }

  render() {
    const {form} = this.state;
    return <FormFinish form={form}/>;
  }
}

export default Loader;