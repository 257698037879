
import React from 'react';
import styled from 'styled-components';
import axios from 'axios';
import {FieldSet, FieldTitle, FieldBody, FieldLabel, FieldDesc} from '../ui/field';
import FormPartsStage from '../ui/form_parts_stage';

class QuestionViewer extends React.Component {
  constructor(props) {
    super();
    const {question, answers} = props;
    const prefix = 'q_' + question.id;
    this.state = {
      question: question,
      my_answer: {
        [prefix]: answers[prefix] || {}
      },
      expanded: []
    };

    this.updateState = props.updateState;
  }
  changeActive(ev, label) {
    const {expanded} = this.state;
    let nextExpanded = expanded;
    if ( expanded.includes(label) ) {
      // 抜く
      nextExpanded = nextExpanded.filter((e) => {
        return e !== label;
      });
    } else {
      // 追加
      nextExpanded = nextExpanded.concat(label);
    }

    this.setState(state => {
      const nextState = {
        ...state,
        expanded: nextExpanded
      };
      return nextState;
    });
  }
  changeValue(ev, prefix, label) {
    const {name, value} = ev.target;
    const my_answer = {
      ...this.state.my_answer, 
      [prefix]: {
        ...this.state.my_answer[prefix],
        [label]: value
      }
    };
    this.setState(state => {
      const nextState = {
        ...state,
        my_answer
      };
      return nextState;
    });
    this.updateState(my_answer);
  }
  changeCheck(ev, prefix, label) {
    const {my_answer} = this.state;
    const {checked, value} = ev.target;
    let list = my_answer[prefix][label] || [];
    list = checked ? list.concat(value) : list.filter((l) => {
      return l !== value;
    });
    const next_answer = {
      ...this.state.my_answer,
      [prefix]: {
        ...this.state.my_answer[prefix],
        [label]: list
      }
    };
    this.setState(state => {
      const nextState = {
        ...state,
        my_answer: next_answer
      };
      return nextState;
    });
    this.updateState(next_answer);
  }

  render() {
    const {question, my_answer, expanded} = this.state;
    let items = question.items_json && JSON.parse(question.items_json);
    const prefix = 'q_' + question.id;
    const answer = my_answer[prefix];

    if ( question.display_type == 1 || question.display_type == 2 ) {
      for( let data in items.values ){
        let match = items.values[data].text.match(/\[(.*)\](.*)/);
        if ( match ) {
          if ( match[1] && match[2] ) {
            items.values[data].parent = match[1];
            items.values[data].text   = match[2];
          }
        }
      }
    }
    let groups = [];
    for( let data in items.values ){
      const parent = items.values[data].parent ? items.values[data].parent : "normal";
      let index = groups.findIndex((g) => {
          return (g.name === parent);
      });
      if ( index == -1 ) {
        index = groups.push({
          name: parent,
          values: []
        });
        index = index - 1;
      }
      if ( question.layout_type == 3 ) {
        if ( answer[`${parent}_item`] && answer[`${parent}_item`].includes(String(items.values[data].id)) ) {
          groups[index].values.push(items.values[data]);
        }
      } else {
        if ( answer.item && answer.item.includes(String(items.values[data].id)) ) {
          groups[index].values.push(items.values[data]);
        }
      }
    }
    items.groups = groups;

    if ( question.layout_type == 0 ) {
      return (<FormPartsTitle data={question}/>);
    } else if ( question.layout_type == 1 ) {
      return (<FormPartsCheck prefix={prefix} data={question} items={items} answer={answer} changeCheck={this.changeCheck.bind(this)} changeValue={this.changeValue.bind(this)} changeActive={this.changeActive.bind(this)} expanded={expanded}/>);
    } else if ( question.layout_type == 2 ) {
      return (<FormPartsRadio prefix={prefix} data={question} items={items} answer={answer} changeCheck={this.changeCheck.bind(this)} changeValue={this.changeValue.bind(this)} changeActive={this.changeActive.bind(this)} expanded={expanded}/>);
    } else if ( question.layout_type == 3 ) {
      return (<FormPartsRadioSep prefix={prefix} data={question} items={items} answer={answer} changeCheck={this.changeCheck.bind(this)} changeValue={this.changeValue.bind(this)} changeActive={this.changeActive.bind(this)} expanded={expanded}/>);
    } else if ( question.layout_type == 4 ) {
      return (<FormPartsStage prefix={prefix} data={question} items={items} answer={answer} changeCheck={this.changeCheck.bind(this)} changeValue={this.changeValue.bind(this)} changeActive={this.changeActive.bind(this)} expanded={expanded} print={true}/>);
    }
  }
}
const FormPartsTitle = ({data}) => (
  <PartsWrap>
    <PartsTitle>{data.text}</PartsTitle>
  </PartsWrap>
);
const PartsGroup = styled.span`
`;
const GroupWrap = styled.div`
  display: inline-block;
`;
const ViewOther = styled.div`
  white-space: pre-wrap;
  span {
    display: inline-block;
    margin: 1em 0 0;
    padding: 0 0 0 1em;
    border-left: 1px solid #6E574D;
  }
`;
const PartsView = styled.div`
  display: inline-block;
  label {
    display: inline-block;

    &:after {
      content: "/";
      margin: 0 20px;
    }
  }
  &:last-child {
    label {
      &:after {
        display: none;
      }
    }
  }
`;
const GroupBox = styled.div`
  display: ${props => props.active ? `block` : `none` };
`;


const FormPartsCheck = ({prefix, data, items, answer, changeCheck, changeValue, changeActive, expanded}) => (
  <PartsWrap>
    <FieldSet>
      <FieldTitle type="large">{data.text}（複数選択可）</FieldTitle>
      <FieldBody type={items.type}>
        {items.groups.map((group) => (
          <GroupBox key={group.name} active={group.values.length > 0}>
            {group.name !== 'normal' && <ParentTitle active={expanded.includes(prefix + group.name)} type={data.display_type == 1 ? "accordion" : "normal"} onClick={(ev) => changeActive(ev, prefix + group.name)}>{group.name}</ParentTitle>}
            <GroupWrap>
              <PartsGroup>
                {group.values.map((v) => (
                  <PartsView key={v.id}>
                    {answer.item&&answer.item.includes(String(v.id)) &&
                      <FieldLabel>{v.text}</FieldLabel>
                    }
                  </PartsView>
                ))}
              </PartsGroup>
            </GroupWrap>
          </GroupBox>
        ))}
        {answer.other && 
          <ViewOther>その他：<br/>
            <span>{answer.other}</span>
          </ViewOther>
        }
        
      </FieldBody>
    </FieldSet>
  </PartsWrap>
);
const FormPartsRadio = ({prefix, data, items, answer, changeCheck, changeValue, changeActive, expanded}) => (
  <PartsWrap>
    <FieldSet>
      <FieldTitle type="large">{data.text}</FieldTitle>
      <FieldBody>
        {items.groups.map((group) => (
          <GroupBox key={group.name} active={group.values.length > 0}>
            {group.name !== 'normal' && <ParentTitle active={expanded.includes(prefix + group.name)} type={data.display_type == 1 ? "accordion" : "normal"} onClick={(ev) => changeActive(ev, prefix + group.name)}>{group.name}</ParentTitle>}
            <GroupWrap key={group.name}>
              <PartsGroup>
                {group.values.map((v) => (
                  <PartsView key={v.id}>
                    {answer.item&&answer.item.includes(String(v.id)) &&
                      <FieldLabel>{v.text}</FieldLabel>
                    }
                  </PartsView>
                ))}
              </PartsGroup>
            </GroupWrap>
          </GroupBox>
        ))}

        {answer.other && 
          <ViewOther>その他：<br/>
            <span>{answer.other}</span>
          </ViewOther>
        }
        {
          answer.item == 1 && answer.year && answer.month &&
          <ViewOther>
            <span>{answer.year}年{answer.month}月</span>
          </ViewOther>
        }
        {
          answer.item == 2 && answer.year_range && answer.month_range &&
          <ViewOther>
            <span>{answer.year_range}年{answer.month_range}</span>
          </ViewOther>
        }

      </FieldBody>
    </FieldSet>
  </PartsWrap>
);
const FormPartsRadioSep = ({prefix, data, items, answer, changeCheck, changeValue, changeActive, expanded}) => (
  <PartsWrap>
    <FieldSet>
      <FieldTitle type="large">{data.text}</FieldTitle>
      <FieldBody>
        {items.groups.map((group) => (
          <GroupBox key={group.name} active={group.values.length > 0}>
            {group.name !== 'normal' && <ParentTitle active={expanded.includes(prefix + group.name)} type={data.display_type == 1 ? "accordion" : "normal"} onClick={(ev) => changeActive(ev, prefix + group.name)}>{group.name}</ParentTitle>}
            <GroupWrap key={group.name}>
              <PartsGroup>
                {group.values.map((v) => (
                  <PartsView key={v.id}>
                    {answer && answer[`${group.name}_item`].includes(String(v.id)) &&
                      <FieldLabel>{v.text}</FieldLabel>
                    }
                  </PartsView>
                ))}
              </PartsGroup>
            </GroupWrap>
          </GroupBox>
        ))}
      </FieldBody>
    </FieldSet>
  </PartsWrap>
);
const ParentTitle = styled.div`
  font-size: 1.2em;
  margin: 20px 0 10px;

  @media (max-width:900px) {
    font-size: 1.4em;
  }

  ${props => {
    switch(props.type) {
        case 'accordion':
        return `
          cursor: pointer;
          position: relative;
          text-indent: 1em;
          vertical-align: middle;

          &:before {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            margin: auto;
            content: "";
            vertical-align: middle;
            left: 4px;
            box-sizing: border-box;
            width: 4px;
            height: 4px;
            border: 4px solid transparent;
            border-left: 4px solid #6E574D;
            margin-right: 5px;
            transform: ${props.active ? `rotate(90deg)` : `rotate(0)`};
            transform-origin: 30% 50%;
          }
        `;
        default:
        return `
            display: block;
        `;
    }
  }}
`;

class FormDetail extends React.Component {
  constructor(props) {
    super();
    const {form, sheet, questions, token} = props;

    this.state = {
      form,
      sheet,
      questions,
      answers: form.answer_json ? JSON.parse(form.answer_json) : {},
      token: token || '',
    };
  }
  render() {
    const {form, questions, answers, token} = this.state;

    return (
      <Wrapper>
        <FormBody token={token} form={form}/>
        {questions.map((q) => <QuestionViewer key={q.id} question={q} answers={answers}/>)}
      </Wrapper>
    );
  }
}

const FormBody = ({token, form}) => (
  <FormBodyWrapper>
    {token &&
      <FormUserdata>
        <User>
          <UserTitle>
            新郎様/Groom
          </UserTitle>
          <UserBody>
            <FieldSet>
              <FieldTitle>お名前</FieldTitle>
              <FieldBody type="view">{form.groom_name}</FieldBody>
            </FieldSet>
            <FieldSet>
              <FieldTitle>フリガナ</FieldTitle>
              <FieldBody type="view">{form.groom_ruby}</FieldBody>
            </FieldSet>
            <FieldSet>
              <FieldTitle>お誕生日</FieldTitle>
              <FieldBody type="view">{form.groom_birthday}</FieldBody>
            </FieldSet>
            <FieldSet>
              <FieldTitle>郵便番号</FieldTitle>
              <FieldBody type="view">{form.groom_zip}</FieldBody>
            </FieldSet>
            <FieldSet>
              <FieldTitle>ご住所</FieldTitle>
              <FieldBody type="view">{form.groom_address}</FieldBody>
            </FieldSet>
            <FieldSet>
              <FieldTitle>メールアドレス</FieldTitle>
              <FieldBody type="view">{form.groom_email}</FieldBody>
            </FieldSet>
            <FieldSet>
              <FieldTitle>電話番号</FieldTitle>
              <FieldBody type="view">{form.groom_tel}</FieldBody>
            </FieldSet>
            <FieldSet>
              <FieldTitle>ご職業</FieldTitle>
              <FieldBody type="view">{form.groom_job}</FieldBody>
            </FieldSet>
            <FieldSet>
              <FieldTitle>会社名</FieldTitle>
              <FieldBody type="view">{form.groom_company}</FieldBody>
            </FieldSet>
          </UserBody>
        </User>
        <User>
          <UserTitle>
            新婦様/Bride
          </UserTitle>
          <UserBody>
            <FieldSet>
              <FieldTitle>お名前</FieldTitle>
              <FieldBody type="view">{form.bride_name}</FieldBody>
            </FieldSet>
            <FieldSet>
              <FieldTitle>フリガナ</FieldTitle>
              <FieldBody type="view">{form.bride_ruby}</FieldBody>
            </FieldSet>
            <FieldSet>
              <FieldTitle>お誕生日</FieldTitle>
              <FieldBody type="view">{form.bride_birthday}</FieldBody>
            </FieldSet>
            <FieldSet>
              <FieldTitle>郵便番号</FieldTitle>
              <FieldBody type="view">{form.bride_zip}</FieldBody>
            </FieldSet>
            <FieldSet>
              <FieldTitle>ご住所</FieldTitle>
              <FieldBody type="view">{form.bride_address}</FieldBody>
            </FieldSet>
            <FieldSet>
              <FieldTitle>メールアドレス</FieldTitle>
              <FieldBody type="view">{form.bride_email}</FieldBody>
            </FieldSet>
            <FieldSet>
              <FieldTitle>電話番号</FieldTitle>
              <FieldBody type="view">{form.bride_tel}</FieldBody>
            </FieldSet>
            <FieldSet>
              <FieldTitle>ご職業</FieldTitle>
              <FieldBody type="view">{form.bride_job}</FieldBody>
            </FieldSet>
            <FieldSet>
              <FieldTitle>会社名</FieldTitle>
              <FieldBody type="view">{form.bride_company}</FieldBody>
            </FieldSet>
          </UserBody>
        </User>
      </FormUserdata>
    }
  </FormBodyWrapper>
);


const Wrapper = styled.div``;

const FormBodyWrapper = styled.div`
`;

const FormUserdata = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 0 0 60px;
  @media (max-width:600px) {
    display: block;
  }
  @media print {
    display: flex;
  }
`;
const User = styled.div`
  width: 48%;
  @media (max-width:600px) {
    max-width: 320px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }
`;
const UserTitle = styled.div`
  color: #6E574D;
  font-size: 14px;
  margin: 0 0 20px;
`;
const UserBody = styled.div`
`;
const PartsWrap = styled.div`
  margin: 0 0 60px;
  page-break-inside: avoid;
`;
const PartsTitle = styled.div`
  margin: 0 0 40px;
  border-bottom: 1px solid #6E574D;
  color: #6E574D;
  font-size: 14px;
`;


class Loader extends React.Component {
  constructor(props) {
    super();

    const {passcode} = props.match.params;

    this.state = {
      passcode, 
      form: {},
      sheet: {},
      questions: {}
    };
  }

  componentWillMount() {
    const {passcode} = this.state;
    axios.get('/api/forms/find', {
      params: {
        passcode
      }
    })
      .catch(_ => console.error('Do not get form.'))
      .then(res => res.data.form)
      .then((form) => {
        this.setState(state => {
          return {form};
        });

        axios.get('/api/sheets/find', {
          params: {
            id: form.sheets_id
          }
        })
        .catch(_ => console.error('Do not get sheet.'))
        .then(res => res.data.sheet)
        .then((sheet) => {
          this.setState(state => {
            return {sheet};
          });

          axios.get('/api/questions/select', {
            params: {
              sheets_id: sheet.id
            }
          })
          .catch(_ => console.error('Do not get questions.'))
          .then(res => res.data.questions)
          .then((questions) => {
            this.setState(state => {
              return {questions};
            });
          });
        })
      });
  }

  render() {
    const {form, sheet, questions} = this.state;
    const token = localStorage.getItem('admin');
    if ( form.id && sheet.id && questions.length > 0 ) {
      return <FormDetail form={form} sheet={sheet} questions={questions} token={token}/>;
    } else {
      return <div/>;
    }
  }
}

export default Loader;