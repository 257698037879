import React, {Component} from 'react';
import styled from 'styled-components';

export default class Copywrite extends Component {
  constructor() {
    super();
  }

  render() {
    return (
      <Wrapper>
        <Text>&copy; GOOD LUCK CORPORATION. ALL RIGHTS RESERVED.</Text>
      </Wrapper>
    );
  }
}

const Wrapper = styled.div`
  position: fixed;
  top: 50%;
  left: 40px;
  transform: rotate(-90deg) translate(-50%, -50%);
  transform-origin: 0 100%;
  @media (max-width:320px) {
    display: none;
  }
  @media print {
    display: none;
  }
`;
const Text = styled.div`
  font-size: 10px;
  `;
