import styled from 'styled-components';

export const FieldSet = styled.div`
    margin: 0  0 10px;
`;
export const FieldTitle = styled.div`
    font-size: 12px;
    margin: 0 0 3px;
    position: relative;

    ${props => {
        switch(props.type) {
            case 'large':
            return `
                font-weight: bold;
                margin: 0 0 15px;
                font-size: 14px;
            `;
            default:
            return '';
        }
    }}
`;
export const FieldSub = styled.div`
    font-size: 12px;
    font-weight: normal;
    position: relative;
    display: inline-block;

    ${props => {
        if(props.marker) {
            return `
                background-color: #ff0;
            `;
        }
    }}

`;

export const FieldBody = styled.div`
    font-size: 12px;

    input {
        &[type=text],
        &[type=date],
        &[type=email] {
            font-size: 16px;
            width: 100%;
            border: 1px solid #ccc;
            padding: 0.3em;
            box-sizing: border-box;
        }
        &[type=checkbox],
        &[type=radio] {
            margin: 0 0.5em 0 0;
        }
    }
    textarea {
        width: 99%;
        height: 7em;        
        font-size: 12px;
        border: 1px solid #ccc;
        box-sizing: border-box;
    }
    ${props => {
        switch(props.type) {
            case 'zip':
            return `
                display: flex;
                justify-content: space-between;

                input {
                    width: 60%;
                    margin-right: 10px;
                }
                button {
                    width: 38%;
                }                
            `;
            case 'view':
            return `
                min-height: 1em;
            `;
            default:
            return '';
        }
    }}

`;
export const FieldLabel = styled.label`
    ${props => {
        switch(props.type) {
            case 'accordion':
            return `
                display: ${props.active ? 'block' : 'none'};
            `;
            default:
            return `
                display: block;
            `;
        }
    }}
    margin: 0 0 10px;
`;
export const FieldWrap = styled.div`
    margin: 0 0 10px;
`;
export const FieldDesc = styled.div``;

