import React, {Component} from 'react';
import styled from 'styled-components';

export default class Header extends Component {
  constructor() {
    super();
  }

  render() {
    return (
      <Wrapper>
        <Logo>
          <LogoHead>風までも、思い出になるリゾートウエディング</LogoHead>
          <LogoImg>
          <svg viewBox="0 0 990 107">
  <style dangerouslySetInnerHTML={{__html: "\n    .cls-2{fill:none;stroke-miterlimit:10;stroke-width:2.5px;stroke:#48322a;} g { fill: #48322a; }\n  " }} />
  <path d="M32.72,23.33,43,29.44a196.94,196.94,0,0,0,17.48-14.2c-32.22,3.68-42.51,5.66-50,7.79L1.06,12.43,2.44,12a20.06,20.06,0,0,0,9,3.62A21.31,21.31,0,0,0,16,15.4c5.82-.87,46.09-5.32,50.16-7.55l.36-.16.31.24a58.23,58.23,0,0,1,6.92,6.76l.76,1-1.22.07A7.33,7.33,0,0,0,70,17.16c-1.29.84-2.81,1.85-4.49,3C59,24.46,50.18,30.36,42.23,34.37,40.1,61,17.59,87.37.7,94.66l-.7-1c13-7.71,35.92-36,35.92-61.79a9.22,9.22,0,0,0-3.16-6.59c-.33-.31-.63-.6-.89-.87Z" />
  <path d="M309.36,2.78,326.44,13l-1.18.48a8.84,8.84,0,0,0-3.22,2.4l-.27.29c-6.57,6.57-9.84,9.63-16.46,15.35v41.7c0,2,0,2.29-.36,3.28a2.57,2.57,0,0,1-2.42,1.78c-2.23,0-4.77-2.34-4.77-4.4a4.92,4.92,0,0,1,.21-1.54,26.55,26.55,0,0,0,.75-7.08V36.78c-10.81,8.13-24.37,15.91-37.08,20.35l-.48-1.05c22.37-12.15,39.44-26.25,51.2-41l.41-.65a7.91,7.91,0,0,0,1.55-3.77c.17-1.69-2.1-4-4.1-5.4-.66-.48-1-.8-1.68-1.25Z" />
  <path d="M761.49,62c18.62-10.3,27.76-17.54,39.39-32.14l.4-.65a7.91,7.91,0,0,0,1.55-3.77c.17-1.69-.65-3-2.55-4.49-.45-.34-.91-.69-1.36-1.06l.78-1.1,14.67,9-1.13.46c-1.29.53-1.77,1.22-2.88,2.38l-.28.29c-6.32,6.32-8.29,8.28-14,13.25l-1,.84V75c0,2,0,2.29-.36,3.28a2.57,2.57,0,0,1-2.41,1.78c-2.3,0-4.54-2.65-4.54-4.62a4.9,4.9,0,0,1,.21-1.54,21.9,21.9,0,0,0,.74-6.45V50c-8.11,5.34-16.25,9-26.75,13Z" />
  <path d="M163.35,34.4c-17.28,0-56,6.67-70,9.62l-.56.2-8.19-8.9L86,35a17.44,17.44,0,0,0,8.47,2.53,19.53,19.53,0,0,0,3.69-.18c11.14-1.41,45.19-4.51,65.24-4.51Z" />
  <path d="M169.78,69.28c9.41-7.73,19.69-25.4,19.69-42.32,0-2.21-2.08-3.87-4.05-5.33-.61-.46-1.25-1-1.75-1.38l.88-1.2,11.88,7.53c-1.79,19.25-10.66,34.74-25.82,43.59Z" />
  <path d="M249.3,36.21c-12.63,17.09-30.82,29-41.74,34.1l-.46-.51a17.37,17.37,0,0,0,.54-5.62c0-.47,0-.31,0-.91l.18-47.51c0-1.9-1.34-3.92-4.18-6.13l.8-1.14,12.62,9-1.07.42c-1.38.54-1.82,1.34-1.82,3.26v38c8.52-4.07,25-13.83,34.3-23.6Z" />
  <path d="M659,54.37l-.45.17c0,.37-.26,1.05-1.38,1.25-4,.7-11.72,1.24-20.17,1.74l-5.35.32V36.77c0-1.93.44-2.72,1.82-3.26l1.07-.42-.92-.69c-1-.75-2.81-2-4.73-3.41l-1.6-1.14c6-.43,12.31-.69,18-.69l3.2-.05c4.21,0,7.4,0,8,.15l.11-.3-.72-.56a60.78,60.78,0,0,1-7.16-7l-.45.21c0,.35-.26.92-1.31,1.11a107,107,0,0,1-13,1.46c-6.17.35-13.84.78-24.74,2.24a27.56,27.56,0,0,1-4.26.34c-2.89,0-6.11-1.83-7.87-3.07l-1.25.5L604.21,31l.38-.11c3.78-1.07,10.75-2,18.62-2.73,1.35,1.48,2.12,2.91,2.12,4.06l-.06,26c-7.35.5-15.14,1.15-22.38,2.12a34.79,34.79,0,0,1-4.91.35c-2.73,0-5.88-1.87-7.72-3.16L589,58l8.38,9.25c7.62-2.16,43.1-5,58.12-5h3.17a76.41,76.41,0,0,1,8.11.17l.13-.28A71.42,71.42,0,0,1,659,54.37Z" />
  <path d="M349,76.15C370.77,62.66,388.45,42,398,20.06c-28.52,3.23-29.64,3.57-34.78,5.1l-2.41.7-8.8-11.18,1.33-.43c1.59,1.34,6.39,4.37,8.65,4.37a28.51,28.51,0,0,0,4.43-.35c6.46-1,12.31-1.6,17.48-2.16,8.52-.92,14.76-1.43,18.44-3.09l.36-.16.31.24a43.77,43.77,0,0,1,6,5.83l.35.44-.27.31-.32,0-.41,0c-.41,0-1.8.14-3.14,2.35a202.92,202.92,0,0,1-14.81,22.51c15.85,8.4,26.36,19.21,26.36,24.4,0,1.42-1,3.46-2.85,3.46s-2.79-1.3-6.17-7.36c-4.67-7.62-11.48-15.11-18.37-19-9.68,11.56-24.61,26-39.8,31.3Z" />
  <path d="M570.49,23.43a41.73,41.73,0,0,0-5.83-5.69l-.31-.24-.36.16c-3.44,1.55-9.86,2.18-18.78,3l-4.51.41V14.21c0-1.93.44-2.72,1.82-3.26l1.07-.42-.92-.69c-1-.75-2.81-2-4.73-3.41-2.59-1.85-5.86-4-7-4.93l-.85,1.08c2.84,2.21,4.27,3.9,4.27,6.06l-.05,13c-3,.33-6.18.71-9.35,1.18-1.66.26-10,.6-11.27.6-2.54,0-6.41-2.68-7.72-3.83l-.85.83c1.65,1.53,5.68,5.75,6.18,7.67.62,2.42.81,11.13.82,11.26a26.65,26.65,0,0,1-.13,7.12,4.93,4.93,0,0,0-.07,1.55c.18,2,2.91,4.16,5.13,4a2.57,2.57,0,0,0,2.25-2c.27-1,.25-1.28.07-3.3l-1.46-17.32c3.24-.59,8-1,15.5-1.78,6.41-.62,15.14-1.47,26.72-2.78C558.19,44.77,545.35,79,521.74,93.71l.73,1c14.48-6,24.42-18.78,30.38-28.61A106.65,106.65,0,0,0,567,26.71a4,4,0,0,1,2.74-2.55l.41,0,.31,0,.34-.36Z" />
  <path d="M406.15,5.16c4.82,1.18,14,5.37,14,9.95,0,1.82-.93,3.07-2.77,3.07-1.52,0-2.29-1.22-2.85-2.41-2-4.63-4.94-8.21-8.62-10Z" />
  <path d="M414.32,0c4.82,1.18,14,5.37,14,9.95,0,1.82-.93,3.07-2.77,3.07-1.52,0-2.29-1.22-2.85-2.41-2-4.63-4.94-8.21-8.62-10Z" />
  <path d="M749.81,25.38l-.38.24c0,.37-.26,1.05-1.38,1.25-4,.7-43,3-54.26,4.48a28.53,28.53,0,0,1-4.43.35c-2.68,0-6.64-2.11-8.14-3.16l-1.28.38,8.52,9.25.38-.11c3.86-1.1,14.77-2.33,26.53-3.28,1.86,1.36,3.86,3,3.7,4.5C716.85,60.36,700.17,73.56,695,77l.87.94C713.29,69.13,722.7,56.34,726,38.86l.08-.43-6.35-4c10.07-.74,20.21-1.24,26.67-1.24h3.17c2.38,0,6.84,0,8.29.17l.15-.15A75,75,0,0,1,749.81,25.38Z" />
  <path d="M699.5,20.06c8.66-2.46,18.47-3.45,31-3.45h3c2.21,0,7.35.19,8.22.34l.1-.17-.71-.55A62,62,0,0,1,734,9.16l-.48.15c0,.35-.25,1-1.3,1.18a85,85,0,0,1-9.38,1.1c-3.41.27-8.07.65-18.59,2.05A27.46,27.46,0,0,1,700,14c-2.53,0-6.24-2-7.85-3.1l-1.2.48,8.18,8.81Z" />
  <path d="M738.53,5.16c4.82,1.18,14,5.37,14,9.95,0,1.82-.93,3.07-2.77,3.07-1.52,0-2.29-1.22-2.85-2.41-2-4.63-4.94-8.21-8.62-10Z" />
  <path d="M746.7,0c4.82,1.18,14,5.37,14,9.95,0,1.82-.93,3.07-2.77,3.07-1.52,0-2.29-1.22-2.85-2.41-2-4.63-4.94-8.21-8.62-10Z" />
  <path d="M921.76,81.18C944.11,65.92,960,42.37,961.94,22.95c-5.12.24-12.18.52-18.06.52l-2.51,0a91.3,91.3,0,0,1-27.09,31.09l-.9-.87c14.85-14.43,23.19-28.54,26.25-42.39.41-1.87-1.94-3.85-4-5.28-.71-.5-1.38-1-1.92-1.43l.82-1.08,12,7.52a78.22,78.22,0,0,1-3.49,9l1.22-.09c7.08-.49,17.32-.86,21.7-2.84l.36-.16.31.24a48.21,48.21,0,0,1,6.23,6.2l-.18.18a4,4,0,0,0-.78-.1c-.74,0-1.77.27-3.08,2.35-3.33,24.12-25.47,46.82-46.41,56.33Z" />
  <path d="M967.32,5.16c4.82,1.18,14,5.37,14,9.95,0,1.82-.93,3.07-2.77,3.07-1.52,0-2.29-1.22-2.85-2.41-2-4.63-4.94-8.21-8.62-10Z" />
  <path d="M975.49,0c4.82,1.18,14,5.37,14,9.95,0,1.82-.93,3.07-2.77,3.07-1.52,0-2.29-1.22-2.85-2.41C981.9,6,979,2.4,975.29.63Z" />
  <path d="M837.88,16.61c7.64.36,21.57,3.54,22.81,10,.47,2.44-.55,4.14-2.73,4.55a3.5,3.5,0,0,1-.66.06c-1.65,0-2.82-1.28-3.74-2.54a28.42,28.42,0,0,0-15.79-11.34Z" />
  <path d="M904.16,25.31C891.73,44.88,867.65,63.12,851,72.64a8.44,8.44,0,0,0-3.41,2.78H847a34.1,34.1,0,0,0-10.1-11.67l.5-.7a16.6,16.6,0,0,0,6,3,4.92,4.92,0,0,0,1,.09c11,0,47.46-26.93,58.56-41.77Z" />
  <circle cx="463.34" cy="40.63" r="6.05" />
  <path className="cls-2" d="M917,84.51c-84,39.06-251.22,21.74-415.79-17.81-70.88-17-172.71-33.44-266.92-33.44-12.79,0-28.55-.24-43.45.18" />
</svg>
          </LogoImg>
          <LogoFoot>ウェルカムシート</LogoFoot>
        </Logo>
      </Wrapper>
    );
  }
}

const Wrapper = styled.div`
  padding: 40px 0;
`;
const Logo = styled.div`
  text-align: center;
`;
const LogoHead = styled.div`
  color: #48322a;
  font-size: 10px;
  margin: 0 0 7px;
`;
const LogoImg = styled.div`
  svg {
    width: 221px;
    height: 24.5px;
    fill: #48322a;
  }
`;
const LogoFoot = styled.div`
  color: #48322a;
  font-size: 16px;
`;
