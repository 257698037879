import React from 'react';
import {ThemeProvider} from 'styled-components';
import {BrowserRouter as Router} from 'react-router-dom';
import styled from 'styled-components';
import theme from './theme';
import Style from './style';
import Mux from './mux';
import Header from './ui/header';
import Copywrite from './ui/copywrite';

export default () => (
  <ThemeProvider theme={theme}>
    <Router>
      <Wrapper>
        <Style/>
        <Header/>
        <Mux/>
      </Wrapper>
    </Router>
  </ThemeProvider>
);

const Wrapper = styled.div`
  width: 800px;
  margin: 0 auto;
  box-sizing: border-box;
  position: relative;

  @media (max-width:900px) {
    width: 100%;
    padding: 0 40px;
  }
  
  @media (max-width:320px) {
    width: 100%;
    padding: 0 20px;
  }
`;
