import React from 'react';
import {Switch, Route} from 'react-router-dom';
import Home from './pages/home';
import Form from './pages/form';
import FormFinish from './pages/form_finish';
import FormPrint from './pages/form_print';

export default () => (
  <Switch>
    <Route exact path="/" component={Home}/>
    <Route exact path="/form/:passcode/" component={Form}/>
    <Route exact path="/form/:passcode/finish" component={FormFinish}/>
    <Route exact path="/form/:passcode/print" component={FormPrint}/>
  </Switch>
);

