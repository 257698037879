import React from 'react';

class Loader extends React.Component {
  constructor() {
    super();
  }

  render() {
    return <></>;
  }
}

export default Loader;